import { event } from "./events"

export const position = {
    id: true,
    value: true,
    withdrawn: true,
    rewardAboveEq: true,
    rewardBelow: true,
    event,
}
